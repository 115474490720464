import * as Sentry from "@sentry/react";

if(import.meta.env.PROD) {
	Sentry.init({
		dsn: "https://22f645280bafde4a5350e212415466c3@o4508257291599872.ingest.de.sentry.io/4508282560708688",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Установите 'tracePropagationTargets' для управления тем, для каких URL-адресов должна быть включена распределенная трассировка
		tracePropagationTargets: ["localhost"],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
