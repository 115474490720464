import {logEvent} from "./logger";
import {getConfig} from "../config";
import {getExternalId} from "./getExternalId.tsx";

declare global {
	interface Window {
		oneSignalError: boolean;
		OneSignalDeferred: ((os: any) => void)[];
	}
}

export function trySubscribeToOneSignal() {
	const externalId = getExternalId();
	return new Promise((done, reject) => {
		if(!window.OneSignalDeferred) return done(false);
		console.log('init one signal');
		if (window.oneSignalError) {
			logEvent('oneSignal loading script error');
			return done(false);
		}
		window.OneSignalDeferred.push(async function (OneSignal) {
			console.log('call OneSignalDeferred', OneSignal);
			await OneSignal.init({
				appId: getConfig().oneSignalAppId,
				autoResubscribe: true,
				autoRegister: false,
			});
			try {
				if(await OneSignal.context.subscriptionManager.isOptedIn()) {
					return done(true);
				}
				OneSignal.Notifications.addEventListener('change', () => {
					done(true);
				})
				await OneSignal.login(externalId);
				await OneSignal.Notifications.requestPermission();
			} catch (e) {
				reject(e);
			}
		});
	})
}
