import {getLocale} from "../config";
import {useState} from "react";

const styles = {
	container: {
		width: "100%",
		height: "30px",
		backgroundColor: "#ddd",
		borderRadius: "5px",
		overflow: "hidden",
		transform: "rotate(-5deg)", // небольшой наклон влево
		boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)", // немного теней для объема
	},
	progress: {
		height: "100%",
		background: "linear-gradient(to right, #4caf50, #2e7d32)", // градиент от зелёного к тёмно-зелёному
		borderRadius: "5px",
	},
};

export function InstallButton(props: { onClick: () => void, isInstalled: boolean, status: string, speed: number }) {
	const [progress, setProgress] = useState(0);
	const [lock, setLock] = useState(false);
	const updateSpeed = 10;

	function onClick() {
		if(lock) return;
		props.onClick();
		setLock(true);
		const interval = setInterval(() => {
			setProgress((prev) => {
				const val = Math.min(prev + (props.speed || 1), 100);
				if(val < 100) return val;
				clearInterval(interval);
				setLock(false);
				setTimeout(() => setProgress(0), 1000);
				return 100;
			});
		}, updateSpeed);
	}

	return (
		<button className="header_btnIns_Btn__uB2nz" onClick={onClick}>
			<div
				style={{
					...styles.progress,
					width: `${progress}%`,
					transition: `width ${updateSpeed}ms linear`,
				}}
				className="header_btnIns_BtnProg__SMou9">
				<div
					className="header_btnIns_BtnProgLine__m+nW7"/>
			</div>
			<div
				className="header_btnIns_BtnTitle__ctqbc">
				{props.isInstalled ? getLocale('app:isInstalled') : props.status || getLocale('app:installButton')}
			</div>
		</button>
	);
}
