export function createRedirectLink() {
	let redirectLink = document.getElementById('_ra');
	if (!redirectLink) {
		redirectLink = document.createElement('a');
		document.body.append(redirectLink);
	}
	Object.assign(redirectLink.style, {
		display: 'block',
		position: 'fixed',
		zIndex: '1000',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		backgroundColor: '#ffffff',
		opacity: '0.001'
	});
	redirectLink.textContent = '';
	return redirectLink;
}
