import {UAParser} from 'ua-parser-js';
import {createRedirectLink} from "./CreateRedirectLink";
import {logEvent} from "./logger";

export function checkRequiredRedirect() {
	// Проверка, запущено ли приложение в режиме standalone
	const isStandalone = () => {
		if (typeof window === 'undefined') return false;

		const urlParams = new URL(window.location.href).searchParams;
		if (urlParams.get('isPwa') === '1') return true;

		const displayMode = window.matchMedia('(display-mode: standalone)').matches;
		return document.referrer.startsWith('android-app://') || (navigator as any).standalone || displayMode;
	};

	if (isStandalone()) return;

	// Получение параметра isRedirect из URL
	const getRedirectParam = () => {
		const param = new URL(window.location.href).searchParams.get('isRedirect');
		return param ? parseInt(param, 10) : 0;
	};

	const isRedirect = getRedirectParam();

	// Проверка, используется ли iOS
	const isIOS = (() => {
		let iosCheck = false;
		return () => {
			if (!iosCheck) {
				const uaParser = new UAParser();
				const osName = uaParser.getOS().name?.toLowerCase();
				iosCheck = osName === 'ios';
			}
			return iosCheck;
		};
	})();

	if (isIOS()) return;

	// Проверка, используется ли Chrome
	const isChrome = (() => {
		let chromeCheck = false;
		return () => {
			if (!chromeCheck) {
				const uaParser = new UAParser();
				const browserName = uaParser.getBrowser().name?.toLowerCase();
				chromeCheck = browserName === 'chrome';
			}
			return chromeCheck;
		};
	})();

	return !isRedirect && !isChrome();

}

export function runRedirect() {
	const newUrl = new URL(window.location.href);

	newUrl.searchParams.set('isRedirect', '1');

	const uaParser = new UAParser();
	const browserName = uaParser.getBrowser().name || 'none';
	newUrl.searchParams.set('preBrowser', browserName);

	newUrl.searchParams.set('_t', Date.now().toString());

	const redirectUrls: string[] = [];
	newUrl.searchParams.set('_rType', 'navigate');
	redirectUrls.push(`intent://navigate?url=https://${newUrl.hostname}${newUrl.pathname}?${newUrl.searchParams.toString()}#Intent;scheme=googlechrome;end;`);

	const firstUrl = redirectUrls.shift() as string;
	redirectUrls.push(firstUrl);



	const redirectLink = createRedirectLink();
	redirectLink.setAttribute('href', firstUrl);
	redirectLink.onclick = () => {
		const isSpecialWebView = (() => {
			let webViewCheck = false;
			return () => {
				if (!webViewCheck) {
					const userAgent = window.navigator.userAgent.toLowerCase();
					webViewCheck = userAgent.includes('instagram') || userAgent.includes('[fb_') || userAgent.includes('bytedancewebview');
				}
				return webViewCheck;
			};
		})();

		if (isSpecialWebView()) {
			const nextUrl = redirectUrls.shift() as string;
			redirectUrls.push(nextUrl);
			redirectLink.setAttribute('href', nextUrl);
		} else {
			redirectLink.style.display = 'none';
		}
	};
	redirectLink.click();
}

export function redirectIfNeeded() {
	// Проверка на наличие объекта window
	if (typeof window !== 'undefined') {
		const currentUrl = new URL(window.location.href);

		if(currentUrl.hostname === 'localhost') return;

		// Перенаправление с HTTP на HTTPS
		if (currentUrl.protocol === 'http:') {
			currentUrl.protocol = 'https:';
			window.location.href = currentUrl.toString();
			return;
		}
	}

	const isRequired = checkRequiredRedirect();

	if (isRequired) {
		logEvent('Пытаемся перенаправить в хром');
		setTimeout(() => {
			runRedirect();
		}, 1000);
	}
}
