export function VerifySymbol() {
	return (
		<svg viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet"
			style={{
				width: '32px',
				height: '36px',
				transform: 'translate3d(0px, 0px, 0px)',
				contentVisibility: 'visible',
				verticalAlign: 'middle',
				paddingBottom: '4px'
			}}>
			<defs>
				<clipPath id="__lottie_element_2">
					<rect width="512" height="512" x="0" y="0"/>
				</clipPath>
				<clipPath id="__lottie_element_4">
					<path d="M0,0 L512,0 L512,512 L0,512z"/>
				</clipPath>
			</defs>
			<g clipPath="url(#__lottie_element_2)">
				<g clipPath="url(#__lottie_element_4)"
				transform="matrix(0.6499999761581421,0,0,0.6499999761581421,-4.399993896484375,79.60000610351562)"
				opacity="1" style={{display: 'block'}}>
					<g transform="matrix(1,0,0,1,256,256)" opacity="1" style={{display: 'block'}}>
						<g opacity="1" transform="matrix(0.8999999761581421,0,0,0.8999999761581421,0,0)">
							<path fill="rgb(68,163,221)" fillOpacity="1"
								d="M102.375,-248.5 C63,-263 32.5,-223.25 0.75,-222.625 C-31,-222 -63.75,-263 -101,-249.125 C-138.25,-235.25 -134,-184 -154.81199645996094,-159.375 C-175.62399291992188,-134.75 -229.5,-143.75 -248,-102.93800354003906 C-264.6600036621094,-66.18399810791016 -222,-31.5 -222,0.18700000643730164 C-222,33.25299835205078 -263,65.625 -248.375,102.81199645996094 C-233.75,140 -181.25,135.75 -158,156.75 C-134.75,177.75 -141,235.75 -99.75,249.125 C-58.5,262.5 -23.25,223 0.625,222.375 C24.5,221.75 62.874000549316406,261.5 101.56199645996094,249.25 C140.25,237 135.5,179.5 157.125,158 C178.75,136.5 234.75,139.5 249.5,101.875 C264.25,64.25 223,26.75 222.75,-1.25 C222.5,-29.25 262.5,-63 249.375,-101.25 C236.25,-139.5 183,-133.5 158.75,-156 C134.5,-178.5 141.75,-234 102.375,-248.5z"/>
						</g>
					</g>
					<g transform="matrix(0.9929699897766113,0,0,1.0264699459075928,253.8919219970703,247.46694946289062)"
					opacity="1" style={{display: 'block'}}>
						<g opacity="1" transform="matrix(1,0,0,1,0,0)">
							<path strokeLinecap="round" strokeLinejoin="round" fillOpacity="0"
								stroke="rgb(255,255,255)" strokeOpacity="1" strokeWidth="50"
								d="M-86.24800109863281,22.211999893188477 C-86.24800109863281,22.211999893188477 -37.12900161743164,69.4520034790039 -37.12900161743164,69.4520034790039 C-37.12900161743164,69.4520034790039 90.49400329589844,-52.82600021362305 90.49400329589844,-52.82600021362305"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
