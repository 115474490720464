import './utils/sentry';
import ReactDOM from 'react-dom/client'
import InstallerApp from './installer';
import {LoaderApp} from "./Loader";
import {logEvent} from "./utils/logger";
import {hasConfig} from "./config";
import {updateSubscription} from "./utils/trySubscribeToSelfPush.tsx";

const sp = new URLSearchParams(window.location.search);

const isPwa = window.matchMedia('(display-mode: standalone)').matches || sp.has('pwa');

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element')

if(!hasConfig()) {
	logEvent('Приложение запущено без конфигурации');
	ReactDOM.createRoot(rootElement).render(<h1>Please provide __appConfig</h1>);
} else if(isPwa) {
	logEvent('start in pwa mode');
	ReactDOM.createRoot(rootElement).render(<LoaderApp />);
} else {
	logEvent('start in install mode');
	ReactDOM.createRoot(rootElement).render(<InstallerApp />)
}

if ('serviceWorker' in navigator) {
	window.addEventListener('load', async () => {
		const registration = await navigator.serviceWorker.register('/sw.js');
		console.log('Service Worker registered with scope:', registration.scope);
		const sp = new URLSearchParams(window.location.search);
		if(sp.has('swUpdate')) {
			await updateSubscription();
			sp.delete('swUpdate');
			window.location.replace(`?${sp.toString()}`);
		}
		registration.addEventListener('updatefound', () => {
			try {
				const newWorker = registration.installing;
				if(!newWorker) return;
				newWorker.addEventListener('statechange', async () => {
					logEvent('Обновление сервис воркера: '+ newWorker.state);
					if (newWorker.state === 'installed') {
						if (navigator.serviceWorker.controller) {
							logEvent('Произошло обновление сервис воркера');
							setTimeout(() => {
								sp.set('swUpdate', '1');
								window.location.href = `?${sp.toString()}`;
							}, 1000);
						}
					}
				});
			} catch (e) {
				logEvent('Не удалось обновить сервис воркер: '+ e);
			}

		})
	});
}
