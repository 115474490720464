import icon from '../../../assets/images/uploadCloud.png';
import {getLocale} from "../../../config";

export function AppSecurityNoDataCollected() {
	const [spanVal = '', textVal = ''] = getLocale('app:dataSecurity:noDataCollected:description').split('|')
	return (
		<div className="content_security_secItem__J-ttM">
			<img src={icon} alt="icon"/>
			<div>
				{getLocale('app:dataSecurity:noDataCollected')}
				<div className="content_security_secItemM__BLD9K">
					<span style={{textDecoration: 'underline'}}>{spanVal}</span> {textVal}
				</div>
			</div>
		</div>
	);
}
