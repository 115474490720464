import {getConfig} from "../config";
import ky from "ky";

let cId = localStorage.getItem('cId');
if(!cId) {
	cId = getConfig().cId;
	localStorage.setItem('cId', cId);
} else {
	if(cId !== getConfig().cId) {
		ky.patch('/api/pwa/mismatch', {
			json: {
				storedCid: cId,
				cId: getConfig().cId,
				rId: getConfig().rId
			}
		}).catch(() => {});
	}
}

export function getExternalId() {
	if(cId) return cId;
	console.warn('didn t have time to get cid');
	return localStorage.getItem('cId') || getConfig().cId;
}
