import {IAppConfig} from "@shared/interfaces/IWebAppWorkerRecord";
import type {defaultAppLocale} from '@shared/_data/defaultAppLocale';

declare global {
	interface Window {
		__appConfig: any;
	}
}

const defaultConfig = {
	pwaStartUrl: '?pwa=true',
}

interface CAppConfig {
	subId: string;
	pwaStartUrl: string;
	cId: string;
	rId: string;
	appUrl: string;
}

export function getConfig() {
	return {...defaultConfig, ...window.__appConfig} as IAppConfig & CAppConfig;
}

export function hasConfig() {
	return !!window.__appConfig;
}


export function getLocale(key: keyof typeof defaultAppLocale) {
	if(window.__appConfig.locale?.[key]) return window.__appConfig.locale?.[key];
	return `🛑 ${key} not found`;
}
