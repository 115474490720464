import {getLocale} from "../../../config";

export function AppSecurityNoInfo() {
	return (
		<div className="content_security_secItem__J-ttM">
			<div>
				{getLocale('app:dataSecurityNoInfo')}
				<div
					className="content_security_secItemM__BLD9K"/>
			</div>
		</div>
	);
}
